<template>
  <div class="img-load">
    <el-upload
      ref="upload"
      class="upload-demo"
      :action="$api.nb_common.newUpload"
      name="multipartFile"
      :on-preview="handlePreview"
      :list-type="listType"
      :on-remove="handleRemove"
      :on-success="setSuccess"
      :on-progress="progressImg"
      :before-upload="beforeUpload"
      :file-list="data.fileList"
      :show-file-list="showFileList"
      :accept="type==='img'?'image/*':'file'"
      :disabled="isEdit"
    >
      <!-- 头像 -->
      <slot name="img" />
      <el-button
        v-if="(listType === 'picture' || listType === 'text') && !isEdit"
        size="small"
        type="primary"
      >
        {{ btnName }}
      </el-button>
      <i
        v-if="listType === 'picture-card'"
        class="el-icon-plus"
      />
    </el-upload>
    <el-dialog size="tiny">
      <img
        width="100%"
        alt=""
      >
    </el-dialog><span
      v-if="showTip"
      class="red"
    >已上传 {{ data.fileList.length }}/{{ max }}</span>
  </div>
</template>

<script>
export default {
  name: 'Loadimg',
  props: {
    data: {
      type: Object,
      required: true
    },
    max: {
      type: String,
      default: '1'
    },
    type: {
      type: String,
      default: 'img'
    },
    // 文件列表是否显示
    showFileList: {
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: true
    },
    showTip: {
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: true
    },
    maxSize: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: 21000000 // 默认大约20M
    },
    listType: {
      type: String,
      default: 'picture' // text 文字/picture 图片列表/picture-card 照片墙
    },
    btnName: {
      type: String,
      default: '点击上传' // 上传按钮文案
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgNum: 0,
      imgName: '',
      bLoad: true
    }
  },
  mounted() {
    this.imgNum = this.data.fileList.length
  },
  methods: {
    // 点击图片时
    handlePreview(file) {
      window.open(file.url)
    },
    // 图片上传前
    beforeUpload(file) {
      if (this.data.fileList.length >= this.max) {
        this.$message.error(`最多只能上传${this.max}张图片`)
        this.bLoad = false
        return false
      } else if (file.size > this.maxSize) {
        this.$message.error('图片体积过大')
        this.bLoad = false
        return false
      }
      this.imgName = file.name
    },
    progressImg(event, file, fileList) {
      if (fileList.length > this.max) {
        this.$refs.upload.abort(file)
        this.$message.error('由于超出图片上传数量导致上传失败，请点击任意一个上传失败图片的删除按钮，清除所有上传失败的图片！')
      }
    },
    // 删除图片时
    handleRemove(file, fileList) {
      let index = this.data.fileList.indexOf(file)
      if (this.bLoad && index !== -1) {
        this.data.fileList.splice(index, 1)
        this.data.imgCode.splice(index, 1)
      } else {
        this.data.fileList.splice(index, 0)
      }
      this.$emit('delFile', this.data)
      this.bLoad = true
    },
    // 图片上传成功
    setSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.imgNum += 1
        this.data.fileList = fileList
        this.data.imgCode.push(response.data.fileId)
        this.$emit('getFile', this.data)
      }
    }
  }
}
</script>

<style scoped>
  .img-load {
    display: inline-block;
  }

  span {
    font-size: 14px;
  }
</style>
