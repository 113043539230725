var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-load" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.$api.nb_common.newUpload,
            name: "multipartFile",
            "on-preview": _vm.handlePreview,
            "list-type": _vm.listType,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.setSuccess,
            "on-progress": _vm.progressImg,
            "before-upload": _vm.beforeUpload,
            "file-list": _vm.data.fileList,
            "show-file-list": _vm.showFileList,
            accept: _vm.type === "img" ? "image/*" : "file",
            disabled: _vm.isEdit,
          },
        },
        [
          _vm._t("img"),
          (_vm.listType === "picture" || _vm.listType === "text") && !_vm.isEdit
            ? _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v(" " + _vm._s(_vm.btnName) + " "),
              ])
            : _vm._e(),
          _vm.listType === "picture-card"
            ? _c("i", { staticClass: "el-icon-plus" })
            : _vm._e(),
        ],
        2
      ),
      _c("el-dialog", { attrs: { size: "tiny" } }, [
        _c("img", { attrs: { width: "100%", alt: "" } }),
      ]),
      _vm.showTip
        ? _c("span", { staticClass: "red" }, [
            _vm._v(
              "已上传 " +
                _vm._s(_vm.data.fileList.length) +
                "/" +
                _vm._s(_vm.max)
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }